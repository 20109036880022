<template>
  <div
    class="checkbox-field step-field"
    :class="[
      {'step-field_focus': fieldFocus},
      {'step-field_render': !stepsField},
      {'step-field_error': errorStatus}
    ]"
  >
    <checkBox
      :checkboxText.sync="checkBoxValue"
      :value.sync="checkBoxChecked"
      :stepsField="stepsField"
      :customClass="customClass"
      :checkboxPlaceholder="checkboxPlaceholder"
      @inputFocusStatus="changeInputFocus"
      @changeCheckBox="changeCheckBox"
    />
    <transition name="fade-validation">
      <span
        v-if="errorStatus"
        class="validation"
      >
        {{ validationErrorMessage }}
      </span>
    </transition>
  </div>
</template>

<script>
import checkBox from '@/components/fields/checkBox';
import validationErrorMessage from '@/locales/validationErrorMessage';
import {
  findIndex, get, find, filter,
} from 'lodash';
import NEW_CHECKBOX from '@/preset/elements/newCheckbox';
import { SUB_TYPE_CHECKBOX } from '@/preset/elements/listSubTypes';

export default {
  name: 'CheckboxField',
  components: {
    checkBox,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    stepsField: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    step: {
      type: Object,
      default: () => {},
    },
    service: {
      type: Object,
      default: () => {},
    },
    lang: {
      type: String,
      default: 'ru',
    },
    fieldsError: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fieldFocus: false,
    };
  },
  computed: {
    errorStatus() {
      return this.fieldsError.includes(this.item.fieldId);
    },
    validationErrorMessage() {
      return validationErrorMessage[this.lang].inputRequired;
    },
    checkBox: {
      get() {
        return this.item;
      },
      set(data) {
        this.$emit('update:item', data);
      },
    },
    checkBoxValue: {
      get() {
        return this.checkBox.value;
      },
      set(data) {
        this.checkBox.value = data;
      },
    },
    checkboxPlaceholder() {
      const checkboxPlaceholder = get(this.checkBox, `placeholder.value.${this.lang}`);
      if (checkboxPlaceholder) {
        return checkboxPlaceholder;
      }
      const checkbox = NEW_CHECKBOX();
      return get(checkbox, `placeholder.value.${this.lang}`);
    },
    checkBoxChecked: {
      get() {
        return this.checkBox.checked;
      },
      set(data) {
        this.checkBox.checked = data;
      },
    },
  },
  methods: {
    changeCheckBox(e) {
      if (!this.stepsField) {
        const { stepId } = this.step;
        const { steps } = this.service;
        const { fieldId } = this.item;
        Object.keys(steps).forEach((key) => {
          const stepsList = steps[key];
          const currentStep = find(stepsList, (step) => step.stepId === stepId);
          const { fields = [] } = currentStep;
          const checkList = filter(fields, ((field) => field.sub === SUB_TYPE_CHECKBOX));
          const currentField = find(checkList, ((field) => field.fieldId === fieldId));
          if (currentField) {
            currentField.checked = e;
          }
        });
      }
    },
    changeInputFocus(e) {
      this.fieldFocus = e;
      if (this.stepsField && e && this.errorStatus) {
        const indexCurrentField = findIndex(this.fieldsError, (field) => field === this.item.fieldId);
        this.fieldsError.splice(indexCurrentField, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../sass/_variables.scss";

  .step-field {
    &_render {
      display: flex;
      align-items: center;
      height: 40px;
      border-radius: $borderRadius;
      transition: border-color .15s ease-in;
      width: 100%;

      &:hover {
        border-color: $color-dodger-blue;
      }
    }
  }
</style>
